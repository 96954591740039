import { ref, watch } from '@vue/composition-api'
import { toastFail } from '@core/utils/prompt'
import moment from 'moment'
import store from '@/store'

export default function summaryQueueList() {
  const headers = [
    { text: '银行账号名', value: 'bankAccountName', sortable: false },
    { text: '银行账户', value: 'bankAccount', sortable: false },
    { text: '开户行', value: 'openingBank', sortable: false },
    { text: '账单月份', value: 'month', sortable: false },
    { text: '打款总金额', value: 'totalMoney', sortable: false },
    { text: '已打款金额', value: 'paidMoney', sortable: false },
    { text: '未打款金额', value: 'unPaidMoney', sortable: false },
  ]
  const queueList = ref([])
  const totalLists = ref(0)
  const startAt = ref(moment().subtract(2, 'M').format('YYYY-MM'))
  const endAt = ref(moment().subtract(2, 'M').format('YYYY-MM'))
  const bankAccountName = ref('')
  const loading = ref(false)
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
    itemsPerPage: 10,
    page: 1,
  })

  const fetchQueueList = () => {
    store.dispatch('summary-list/fetchLists', {
      pageSize: options.value.itemsPerPage,
      page: options.value.page,

      // sortBy: options.value.sortBy[0],
      // sortDesc: options.value.sortDesc[0],
      startTime: startAt.value,
      endTime: endAt.value,
      bankAccountName: bankAccountName.value,
    })
      .then(response => {
        const { data, total } = response.data
        totalLists.value = total
        queueList.value = data
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        toastFail(error)
        console.log(error)
      })
  }

  const resolveShowTypeText = type => {
    if (type === 0) return '待设置'
    if (type === 1) return '显示'
    if (type === 2) return '不显示'

    return type
  }

  const resolveSendStatusText = type => {
    if (type === 0) return '未确认'
    if (type === 1) return '已确认'
    if (type === 2) return '发送失败'

    return type
  }

  watch([options], () => {
    loading.value = true
    fetchQueueList()
  })

  return {
    fetchQueueList,
    totalLists,
    queueList,
    options,
    headers,
    loading,
    startAt,
    endAt,
    bankAccountName,
    resolveShowTypeText,
    resolveSendStatusText,
  }
}
