<template>
  <div>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          收款汇总
        </slot>
        <v-spacer></v-spacer>

        <v-menu
          v-model="menu1"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="startAt"
              placeholder="月份"
              outlined
              dense
              hide-details
              class="invoice-list-search me-3"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startAt"
            type="month"
            locale="zh-cn"
            no-title
            scrollable
            color="primary"
            @input="menu1 = false, menu2 = true"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu2"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="endAt"
              placeholder="月份"
              outlined
              dense
              hide-details
              class="invoice-list-search me-3"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endAt"
            type="month"
            locale="zh-cn"
            :allowed-dates="allowedDates"
            no-title
            scrollable
            color="primary"
            @input="menu2 = false"
          >
          </v-date-picker>
        </v-menu>
        <v-text-field
          v-model="bankAccountName"
          placeholder="银行账户名"
          single-line
          dense
          outlined
          hide-details
          class="invoice-list-search me-3"
          @keydown.enter="search"
        />
        <v-btn
          color="primary"
          dark
          class="mr-3"
          @click="search"
        >
          <v-icon>mdi-magnify</v-icon>
          查询
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="d-flex mb-n5 warning--text">
        <div class="ma-3">
          打款总金额：¥ {{ generalData.totalMoney }}
        </div>
        <div class="ma-3">
          已打款总金额：¥ {{ generalData.paidMoney }}
        </div>
        <div class="ma-3">
          未打款总金额：¥ {{ generalData.unPaidMoney }}
        </div>
        <v-spacer></v-spacer>
      </v-card-subtitle>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              :headers="headers"
              :items="queueList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <!-- taxPoint -->
              <template #[`item.taxPoint`]="{item}">
                <span>{{ item.incomeTotal&&item.taxPoint?item.incomeTotal/(1-item.taxPoint/100):'0' }}</span>
              </template>

              <!-- action -->
              <template #[`item.action`]="{item}">
                <router-link
                  class="noUnderline"
                  :to="{name:'billSettlement-report',query:{id:item.id} }"
                >
                  查看报告
                </router-link>
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, watchEffect } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'
import storeModule from './queueStoreModule'
import summaryQueueList from './summaryQueueList'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'summary-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const generalData = ref({})
    const isPlanUpgradeDialogVisible = ref(false)
    const isDialogVisible = ref(false)
    const menu1 = ref(false)
    const menu2 = ref(false)

    const {
      queueList,
      fetchQueueList,
      headers,
      options,
      loading,
      totalLists,
      startAt,
      endAt,
      bankAccountName,
      resolveShowTypeText,
      resolveSendStatusText,
    } = summaryQueueList()

    const allowedDates = time => moment(time).isSameOrAfter(moment(startAt.value).format('yyyy-MM'))

    const search = () => {
      loading.value = true
      options.value.page = 1
      fetchQueueList()
    }

    const fetchCount = () => {
      store.dispatch('summary-list/fetchCount', {
        startTime: startAt.value,
        endTime: endAt.value,
        bankAccountName: bankAccountName.value,
      })
        .then(response => {
          if (response.data.status === 200) {
            generalData.value = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const sign = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    watchEffect(fetchCount)

    return {
      queueList,
      totalLists,
      fetchQueueList,
      headers,
      open,
      isRemoved,
      options,
      loading,
      bankAccountName,
      resolveShowTypeText,
      resolveSendStatusText,
      startAt,
      endAt,
      menu1,
      menu2,
      generalData,
      isPlanUpgradeDialogVisible,
      isDialogVisible,
      allowedDates,

      search,
      sign,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
